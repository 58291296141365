
$primary: #2CADF2;
$default-text-color: #38528C;
$default-dark-text-header: #38528C;
$default-light-text-header: #ffffff;
$default-light-text: #ffffff;
$bg-color-default-dark: #5c58ac;

$wrapper-small-padding-top-default: 2rem;
$wrapper-small-padding-bottom-default: 2rem;

$wrapper-padding-top-default: 5rem;
$wrapper-padding-bottom-default: 6rem;


body {
	font-family: 'Lato', sans-serif;
	color: $default-text-color;
	padding: 5.7rem 0 0 0;
	height: 101% !important;
	overflow-x: hidden;
}

body.shopify-order-status {
	padding-top: 0;
	font-size: 0.9rem;

  .wrap {
    padding: 0;
  }
  .main__footer {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
	article.has-subnav {
		padding-top: 50px;
	}
}
@include media-breakpoint-up(xl) {
	article.has-subnav {
		padding-top: 70px;
	}
}



a {transition: all 0.2s linear;}
a:active {
	color: #884fa8;
}
.a-img-hover:hover, .a-img-hover:active {
	opacity: 0.85;
}
a.whiteLink {
  text-decoration: underline;
  color: #fff;
  &:hover, &:focus {
    color: rgba(255,255,255,0.8);
  }
  &:active {color: #83cafb;}
}

a.disabled, .btn--disabled {
	cursor: default !important;
}

.btn--disabled, .btn--disabled:hover {
	/* For Shopify */
	color: #fff !important;
}

*::selection {background: $primary;}

.btn {
	border-radius: 30px;
	text-transform: uppercase;
	font-weight: 300;
}
.btn.btn--subdued {
	/* For Shopify */
	border-color: rgba(179,179,179,0.5);
	padding-left: 1em;
	padding-right: 1em;
}
.btn--subdued:hover {
	background-color: rgba(44,173,242,0.06);
	color: #0e9de9;
}
.btn--subdued:active {
	border-color: #0e9de9;
	box-shadow: 0 0 0 0.2rem rgba(44,173,242,0.5);
}

.btn-content {display: flex;}

.icon-info {
	vertical-align: middle;
	margin-top: -4px;
	width: 23px;
	height: 23px;
	background: $primary;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	&::before {
		/* override Unify */
		content: '' !important;
	}
	&:hover {
		background: #f73daa;
		transition: all 0.2s linear;
	}
	img {
		display: block;
		margin: 4px auto 0 auto;
		width: 5px;
	}
}
.icon-info-no-link {
	vertical-align: top;
    width: 23px;
    height: 23px;
    background: $primary;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 0.5rem;
	img {
		display: block;
		margin: 4px auto 0 auto;
		width: 5px;
	}
}

.tooltip-inner {
	.icon-info-no-link {
		vertical-align: middle;
		width: 21px;
		height: 21px;
		border-radius: 50%;
		margin-right: 0.3rem;
		margin-left: 0.3rem;
		img {
			margin: 4px auto 0 auto;
			width: 4px;
		}
	}	
}

#initialTooltip {
	z-index: 10;
	position: relative;
}
.tooltip-container {
	padding-right: 0.4rem;
	display: inline-block;
}
.tooltip {
	z-index:1;
}
.tooltip-inner {
    background-color: #8c3cbd;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #8c3cbd;
}

.banner-unify-default {background: #e2e2e2;}

header {
	position: fixed;
	top: 0;
	z-index: 1100;
	width: 100%;
	transition: width 2s;
}

main {
	width: 100%;
	position: relative;
	z-index: 1;
}
.wrapper {
	overflow-x: hidden;
}

.navbar {
	background: url(../images/bg-masthead-mobile-600.jpg) center top no-repeat $bg-color-default-dark;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-font-smoothing: antialiased;
}
.navbar-primary {transition: all 0.5s;}
.navbar-brand:hover, .navbar .brand:hover {opacity: 0.8;}
.navbar-brand.disabled {opacity: 1;}
.navbar-brand:hover ~ #navbarNavDropdown .navbar-primary .nav-home .nav-link {
	color: rgba(255,255,255,0.8);
}

.navbar-toggler {
	margin-right: 0 !important;
	padding: 0.75rem 0.8rem;
}



/* Animate Hamburger menu icon */

.navbar-toggler .icon-bar:nth-child(1) {
  transform: rotate(45deg) translate(3px, 3px);
  transition: ease all .2s;
}

.navbar-toggler .icon-bar:nth-child(2) {
  opacity: 0;
  transition: ease all .2s;
}

.navbar-toggler .icon-bar:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
  transition: ease all .2s;
}

.navbar-toggler.collapsed .icon-bar {
  background-color: #fff;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all .2s;
  opacity: 1;
}

.navbar-toggler .icon-bar {
  display: block;
  width: 19px;
  height: 2px;
  border-radius: 1px;
  background: #fff;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 3px;
}


.btn-navbar.collapsed .icon-bar:nth-child(2) {
  -webkit-transform: translateY(0) rotate(0);
          transform: translateY(0) rotate(0);
}
.btn-navbar.collapsed .icon-bar:nth-child(3) {
  opacity: 1;
}
.btn-navbar.collapsed .icon-bar:nth-child(4) {
  -webkit-transform: translateY(0) rotate(0);
          transform: translateY(0) rotate(0);
          margin-top: 3px !important;
}
.btn-navbar .icon-bar:nth-child(2) {
  -webkit-transform: translateY(200%) rotate(45deg);
          transform: translateY(200%) rotate(45deg);
}
.btn-navbar .icon-bar:nth-child(3) {
  opacity: 0;
}
.btn-navbar .icon-bar:nth-child(4) {
  -webkit-transform: translateY(-300%) rotate(-45deg);
          transform: translateY(-300%) rotate(-45deg);
          margin-top: 3px !important;
}

.btn-navbar {animation: fadein 5s;}
@keyframes fadein {from { opacity: 0; } to   { opacity: 1; }}




/* END Animate Hamburger menu icon */

.navbar-primary, .navbar-secondary {margin-top: 2rem; margin-bottom: 2rem;}

.navbar-primary ul {
	padding-left: 1.5rem;
	li {list-style: none;}
}

.sub-header-text {
	font-weight: 400;
	width: 82%;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.1rem;
}

h1, .h1 {
    font-size: 2rem;
    /*font-weight: 600;
    margin-bottom: 3rem;*/
}

h1, h2 {
	sup {
		top: -1em;
		font-size: 50%;
	}
}

h3, .h3 {
	font-size: 1.4rem;
}

h4, .h4 {font-weight: 600;}

h5, .h5 {font-weight: 600;}

h6, .h6 {
	font-size: 1.1rem;
	font-weight: 600;
}

.hdr-alt {
	color: #8c3cbd;
	font-size: 100%;
	font-weight: 600;
}

.hdr-alt-sub {
	font-size: 130%;
	font-weight: 600;
}

.header-icon-row {
	margin-bottom: 1.5rem;
	display: flex;
	align-items: center;
	.icon-header {
		margin-right: 1.5rem;
	}
	h1, h2 { 
		margin-bottom: 0;
	}
}

.alt-h4 {font-size: 1.2rem;}

.footer-wrapper {
	background: url(../images/bg-footer.jpg) center top no-repeat;
    background-size: cover;
    color: $default-light-text;
    font-size: 0.95rem;
    clear: both;
    .footer-brand {
    	text-align: center;
    	img {width: 180px;}
    	a:hover {opacity: 0.8;}
    	.disabled {opacity: 1 !important;}
    }
}
#shopify-section-general-footer {
    clear: both !important;
}

.row-footer {text-align: center;}

.hdr-footer-contact {
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.2rem;
	letter-spacing: 1px;
	margin-top: 0.5rem;
}
.footer-email {
	font-size: 1.1rem;
	margin-bottom: 0.7rem;
}
.footer-local-phone {
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
}
.footer-toll-free {
	font-size: 1.5rem;
	font-weight: 600;
	letter-spacing: 0.9px;
}

.logo-shd {
	max-width: 140px;
	background-color: initial;
	font-size: 0.95rem;
}

.box-dark {
	border-radius: 16px;
	background-color: rgba(56,82,140,0.8);
	padding: 1rem;
	.btn {
		margin-top: 1rem;
	}
}

.social-media {
	padding-top: 2rem;
	a {
		margin-right: 0.7rem;
		img {width: 30px; height: 30px;}
	}
}
.icon-cart-footer {
	display: inline-block;
	margin-left: 0.4rem;
	margin-top: -2px;
}

.copyright-shd {
	font-size: 75%;
	line-height: 1.4rem;
}

// Misc Elements
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .img-fluid {width: 100%;}
}

.txt-light {font-weight: 400;}
.inline-header {
	font-size: 110%;
	font-weight: 600;
}
.icon-cart {
	margin-left: 0.5rem;
	opacity: 0.8;
}

.row-callout {
	border-radius: 8px;
	background-color: rgba(255,255,255,0.12);
	text-align: center;
	color: #fff;
	padding: 1.5rem;
	margin-top: 2rem;
	display: block;
	line-height: 2rem;
	&:hover {
		color: #fff;
		text-decoration: none;
		background-color: rgba(255,255,255,0.22);
	}
	&:active, &:focus {
		background-color: rgba(115,44,158,0.8);
	}
	.row-callout-icon-left {
		margin-right: 1rem;
	}
	.row-callout-icon-right {
		margin-left: 1rem;
	}

	h3 {
		line-height: 1.6;
	}
}

.row-callout-light {
	background-color: #9557bb;
	&:hover, &:focus {
		background-color: #78409a;
	}
}

.input-callout {
	background-color: rgba(255,255,255,0.18);
	border: none;
	padding: 0.8rem 1rem;
	line-height:normal;
	&::placeholder {
		color: #fff !important;
	}
	color: #fff !important;
}

.input-callout:focus {
    color: #fff !important;
    background-color: rgba(255,255,255,0.3);
}

.icon-info {
	margin-left: 0.4rem;
}

.p-extra-margin {
	margin-top: 2rem;
}

.col-align-top {
	padding-top: 1.5rem;
}

.img-align-space {
	width: 80%;
	display: block;
	margin: 0 auto;
}
.img-border {
	border: 1px solid #e0e0e0;
}
.block-shadow-1 {
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.65);
}

.mouse-scroll-container {
	position: absolute;
	left: 50%;
  bottom: 1.5rem;
  transform: translateX(-50%);
}

.list-faq {
	li{
		a {font-size: 1rem;}
	}
}
.faq h5 {font-size: 1.5rem;}

.logo-large-screen {display: none;  transition: all 0.2s;}
.logo-small-screen {display: block; width: 150px; transition: all 0.2s;}
.shrink .logo-small-screen {width: 110px; transition: all 0.5s;}


.show-only-mobile {display: block;}
.hide-only-mobile {display: none;}

.container-anchor {
	margin-top: -100px;
	padding-top: 100px;
	position: relative;
	z-index: -1;
}

%phone-styles {
	border-radius: 14% / 7%;
	box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.5);
}

.round {
	border-radius: 50%;
}

@include media-breakpoint-up(sm) {
	.navbar {
		background: url(../images/bg-masthead-mobile.jpg) center top no-repeat $bg-color-default-dark;
	}
	.show-only-mobile {display: none;}
	.hide-only-mobile {display: block;}
	.container-anchor {
		margin-top: -140px;
		padding-top: 140px;
	}
}

@include media-breakpoint-up(md) {
	body {
		font-size: 1.1rem;
	}
	.btn {
		font-size: 1.2rem;
	}
	.btn-row {
		margin-top: 2.3rem;
	}
	.h1, h1, .h2, h2 {
	    font-size: 2.5rem;
	    margin-bottom: 1.5rem;
	}
	.h3, h3 {
	    font-size: 1.95rem;
	    margin-bottom: 1.2rem;
	}
	.navbar {
		background: url(../images/bg-masthead-small.jpg) center top no-repeat $bg-color-default-dark;
	}
	.sub-header-text, .txt-light {font-weight: 300;}
	.sub-header-text {
	    font-size: 1.5rem;
	    a {
			font-size: 1.2rem;
		}
	}

	.subnav-item {
		a {
			font-size: 0.8rem !important;
		}
	}

	.guide .nav-pc-guide a,
	.the-science .nav-the-science a,
	.faq .nav-faq a,
	.reviews .nav-reviews a
	{color: #d490f7}

	.navbar-expand-md .navbar-collapse {
		margin-left: 1.6rem;
	}

	.box-dark {
		border-radius: 23px;
		padding: 2.2rem;
		text-align: left;
	}

	.p-restrict-width, .sub-header-restrict-width {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
}


@include media-breakpoint-up(lg) {
	body {font-size: 1.2rem;}
	
	.container-anchor {
		margin-top: -160px;
		padding-top: 160px;
	}
	.col-footer-powered {padding-top: 0.5rem;}
	.row-callout {
		margin-top: 6rem;
		line-height: 1.5rem;
	}

	.footer-wrapper {
		.footer-brand {
			text-align: left;
			img {width: auto;}
		}
	}
	.row-footer {text-align: left;}

	.mouse-scroll-container {bottom: 11rem;}
}

@include media-breakpoint-up(xl) {
	body {
		font-size: 1.4rem;
	}
	.alt-h4 {font-size: 1.5rem;}
	.logo-large-screen {display: block;}
	.logo-small-screen {display: none;}
	.btn-lg, .btn-group-lg>.btn {padding: .8rem 3.5rem;}
	.navbar-primary, .navbar-secondary {margin-top: 0;}
	.navbar-primary ul {display: none;}
	.input-callout {padding: 1rem 1.5rem; }

	.footer-wrapper {padding: 5rem 0 !important;}
}

// Navbar breakpoints

/*.logo-large-screen img {
	width: 30px;
}*/

.wrapper-subnav {
	background-image: linear-gradient(92deg, #8428a8, #274c9e);
	padding-top: 0 !important; 
	padding-bottom: 0 !important;
	text-align: center;
}

.subnav {
	margin: 0;
	padding: 0;
	li {
		display: inline;
		a {
			color: rgba(255, 255, 255, 1);
			font-size: 0.9rem;
			text-transform: uppercase;
			letter-spacing: 0.4px;
			padding: 0.9rem 0.7rem;
			text-decoration: none;
			display: inline-block;
		}
	}
}

.subnav a:hover, .subnav a:focus {
	color: rgba(255, 255, 255, 0.8);
}

.subnav a:active {color: rgba(131,202,251,1);}
.subnav .active {color: #d490f7 !important;}
.subnav .disabled {cursor: default;}
.guide .nav-pc-guide .nav-link, .the-science .nav-the-science .nav-link, .faq .nav-faq .nav-link, .reviews .nav-reviews .nav-link {
	color: #d490f7;
}
.nav-footer-primary {
	ul {
		padding-left: 1.4rem;
        margin: 0;
	}
	li ul li {
		list-style: none;
		a {
			padding-left: 0;
		}
	}
	a {
		color: #fff;
		text-transform: uppercase;
		&:hover, &:focus {
			color: rgba(255,255,255,0.8);
		}
		&:active {color: rgba(131,202,251,1);}
	}
	.active {
		color: #fff;
	}
	.disabled {
		color: #fff !important;
		cursor: default;
		font-weight: 600;
	}
}
@media (min-width: 992px) {
	.nav-footer-primary {
		padding-bottom: 1em;
	}
}

.nav-footer-secondary {
	ul {
		padding-left: 1.4rem;
        margin: 0;
	}
	li {
		margin: 0;
	}
	li ul li {
		list-style: none;
		a {
			padding-left: 0;
		}
	}
	a {
		color: #e9c6fe;
		text-transform: uppercase;
		&:hover, &:focus {
			color: rgba(233,198,254,0.8);
		}
		&:active {color: rgba(255,255,255,1);}
	}
	.active {
		color: #e9c6fe;
		font-weight: 600;
	}
	.disabled {
		color: #e9c6fe !important;
		cursor: default;
	}
}


.nav-footer-primary .nav-link, .nav-footer-secondary .nav-link {white-space: nowrap; text-align: left;}

.col-guide-nav {padding-top: 0 !important;}
.nav-guide {
	.nav-link {
		font-size: 0.85rem;
		width: 33.333%;
	}
	.nav-link:nth-child(3n+1) {
		padding-left: 0;
	}
}

.navbar {transition: all 0.2s;}
.shrink .navbar {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	transition: all 0.5s;
}
.navbar-primary {float: left; width: 57%;}
.navbar-secondary {float: right; width: 37%;}
.navbar-primary .nav-link, .navbar-secondary .nav-link {color: #fff;}
.navbar-primary .nav-link span, .navbar-secondary .nav-link span {
	display: inline-block;
}


.navbar-primary .nav-link, #mainNav .nav li a {
	color: rgba(255,255,255,1);
	font-size: 1rem;
}
.navbar-secondary .nav-link {
	color: rgba(212,179,232,1);
	font-size: 0.9rem;
	margin-top: 2px;
}

.navbar-primary .nav-link:hover, #mainNav .nav li a:hover, .navbar-primary .nav-link:focus, #mainNav .nav li a:focus {color: rgba(255,255,255,0.8);}
.navbar-primary .nav-link:active, #mainNav .nav li a:active {color: rgba(131,202,251,1);}

.navbar-secondary .nav-link:hover, .navbar-secondary .nav-link:focus {color: rgba(212,179,232,0.8);}
.navbar-secondary .nav-link:active {color: rgba(255,255,255,1);}

.navbar-primary .active span {
	border-bottom: 2px solid #83cafb;
	color: #fff;
}
#mainNav .nav .active:hover, #mainNav .nav .active:focus, #mainNav .nav .active:active {
	color: rgba(255,255,255,1) !important;
}
.navbar-primary span {border-bottom: 2px solid transparent;}
.navbar-primary .disabled {cursor: default; }
.navbar-primary .disabled span {border-bottom: 2px solid #83cafb !important;}
.navbar-primary-sub-page .active span {
	border-bottom: 2px solid rgba(255,255,255,0.7);
}
.navbar-secondary .active span {
	border-bottom: 2px solid rgba(255,255,255,0.7);
	color: rgba(212,179,232,1);
}
.navbar-secondary .disabled {
	cursor: default;
	color: rgba(212,179,232,1) !important;
}
.navbar-secondary .disabled span {border-bottom: 2px solid #83cafb !important;}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: #d490f7 !important;
}

.menu-button .nav-link {
	border-radius: 20px;
	background: $primary;
	color: #fff !important;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0.8rem !important;
	padding-right: 0.8rem !important;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	display: inline-block;
}

.menu-button .nav-link:hover, .menu-button .nav-link:active {background: #0e9de9;}

.menu-button .nav-link:active {box-shadow: 0 0 0 0.2rem rgba(44,173,242,0.5);}


@include media-breakpoint-up(sm) {
	/*.col-guide-nav {
		padding-left: 0;
	    padding-right: 0;
	}*/
	.nav-guide {
		.nav-link {
			white-space: nowrap;
		}
	}
}
#mainNav .nav li a {
	padding-left: 0;
	padding-right: 0;
}
#mainNav .nav li a.active {
	font-weight: 400;
	&::after {
		content: "";
		background: rgba(255,255,255,0.7);
		position: absolute;
		height: 2px;
		bottom: 0.8rem;
		width: 100%;
		left: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}
#mainNav .nav li a.active.disabled {
	font-weight: 400;
	&::after {
		content: "";
		background: #83cafb;
		position: absolute;
		height: 2px;
		bottom: 0.8rem;
		width: 100%;
		left: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

@include media-breakpoint-up(md) {
	.col-guide-nav {
		padding-left: 20px;
	    padding-right: 20px;
	}
	.navbar-primary .nav-link {
		font-size: 1rem;
	}
	

	.navbar-secondary .nav-link {
		font-size: 0.85rem;
	}
}



@include media-breakpoint-up(xl) {
	.logo-large-screen {width: 42px; transition: all 0.5s;}
	.shrink .logo-large-screen {width: 24px; margin-right: 16px; transition: all 0.5s;}

	.navbar-primary {float: none; width: auto; margin-bottom: 0;}
	.navbar-secondary {float: none; width: auto; margin-bottom: 0;}
	.navbar-nav .nav-link {
		padding-left: 0.8rem !important;
		padding-right: 0.8rem !important;
		text-transform: uppercase;
	}

	#mainNav .nav li a {
		margin-left: 0.8rem !important;
		margin-right: 0.8rem !important;
		text-transform: uppercase;
		position: relative;
	}
	
	.menu-button .nav-link {
		border-radius: 30px;
		background: $primary;
		color: #fff !important;
		margin-left: 0.6rem;
		margin-right: 0.6rem;
		padding-left: 1.1rem !important;
		padding-right: 1.1rem !important;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.nav-guide {
		.nav-link {
			font-size: 1.1rem;
		}
	}
	.navbar {
		background: url(../images/bg-masthead.jpg) center center no-repeat $bg-color-default-dark;
		background-size: cover;
	}

	.shrink .navbar {
		padding-top: 0.41rem;
		padding-bottom: 0.41rem;
		transition: all 0.5s
	}
	.subnav a {padding: 0.9rem 1rem;}
}




// Sections

.wrapper, .wrapper-pc {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	position: relative;
	z-index: 1;
}
.wrapper-main {text-align: center;}
.wrapper-default {
	padding-top: $wrapper-small-padding-top-default;
	padding-bottom: $wrapper-small-padding-bottom-default;
}

.wrapper-dark {color: $default-light-text;}

.wrapper-footer-callout {
	background: url(../images/bg-footer-callout.jpg) center center no-repeat #9c69b0;
	background-size: cover;
	color: #fff;
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;
	h3 {
		text-transform: uppercase;
		font-size: 170%;
		font-weight: 300;
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
	}
	input, .btn {margin-top: 1.5rem;}
}

// Sections - Home
.wrapper-home-main {
    background: url(../images/bg-home-main.jpg) center bottom no-repeat;
    background-size: contain;
    padding-bottom: 9rem;
}

.wrapper-home-2 {
	/*background: url(../images/bg-texture-pattern.jpg) center center no-repeat;
	background-size: cover;*/
	overflow-y: hidden;
	&::after {
		content: "";
	    position: absolute;
	    right: 0;
	    left: 0;
	    bottom: 0;
	    width: 100%;
	    height: 140%;
	    background: url(../images/bg-texture-pattern.jpg) center top no-repeat;
		background-size: cover;
		z-index: -1;
	}
	h1, h2 {
		text-align: center;
	}
}

.wrapper-home-3 {
	background: #ffffff;
	background: -moz-linear-gradient(top, #ffffff 1%, #edebe4 100%);
	background: -webkit-linear-gradient(top, #ffffff 1%,#edebe4 100%);
	background: linear-gradient(to bottom, #ffffff 1%,#edebe4 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edebe4',GradientType=0 );
	.logo-sensor {
		margin-bottom: 1.5rem;
		margin-top: 1rem;
		max-width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
	.col-clinical-results {
		margin-top: -3rem;
	}
}

.logo-mayo-clinic {
	width: 40% !important;
	margin-left: auto;
	margin-right: auto;
	height: auto;
	margin-top: 2rem;
}



// Sections - Stillbirth
.wrapper-stillbirth-main {
    /*background: url(../images/bg-stillbirth.jpg) center top no-repeat;
    background-size: contain;*/
    text-align: center;
    padding-bottom: 4rem;
    .row-stillbirth-articles {
    	.col-article {
    		margin-top: 1.5rem;
    	}
    }
}

.wrapper-stillbirth-2 {
	/*background: linear-gradient( rgba(58, 75, 116, 0.6), rgba(58, 75, 116, 0.6) ), url(../images/bg-stillbirth-2.jpg) center center no-repeat;
	background-size: cover;*/
	background: #364b76;
	text-align: center;
	background: url(../images/bg-texture-pattern.jpg) center center no-repeat;
    background-size: cover;
}


.wrapper-stillbirth-3 {
	position: relative;
	background: linear-gradient(to bottom, rgba(242,242,238,1) 0%, rgba(255,255,255,1) 100%);
}


.wrapper-harmful-sleep-2 {
	padding-top: 2rem;
	padding-bottom: 4rem;
	h1, h2 {text-align: center;}
	.icon-down-arrow {
		margin: 2rem auto;
		display: block;
	}
	.img-supine-sleep {
		margin: 2rem auto;
		display: block;
	}
}

.wrapper-harmful-sleep-3 {
	padding-bottom: 2rem;
	padding-bottom: 4rem;
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(242,241,235,1) 100%);
	h1, h2 {text-align: center;}
	.row-risks {
		padding-bottom: 2rem;
		color: #8c3cbd;
		.hdr-alt {
			margin-bottom: 0;
			white-space: nowrap;
		}
		p {margin-bottom: 0;}
		.col-risk-text {
			padding-left: 20px;
		}
	}
}

.wrapper-harmful-sleep-4 {
	padding-bottom: 2rem;
	padding-bottom: 4rem;
	text-align: center;
	.inline-image {max-width: 80%;}
	.img-phone-app {
		max-width: 234px;
		@extend %phone-styles;
	}
	.col-harmful-sleep-4-info {
		color: $default-text-color;
		text-align: left;
	}
	.small {font-size: 80%;}
}


.wrapper-pc-2, .wrapper-pc-3  {text-align: center;}

.wrapper-pc-3 {
	.col-app-screens {
		margin-bottom: 1.5rem;
		text-align: center;
		.hdr-track {
			text-align: center;
			font-size: 100%;
		}
	}
}

.hdr-track {
	text-align: left;
}

.img-screens {
	border-radius: 10px;
	border: 1px solid #969696;
}

.wrapper-pc-4 {
	background: url(../images/bg-texture-pattern.jpg) center center no-repeat;
	background-size: cover;
	padding-bottom: 2rem;
	padding-bottom: 4rem;
	overflow-y: hidden;
	.row-content {
		padding-bottom: 3rem;
	}
	h1, h2 {
		text-align: center;
	}
}

.wrapper-guide-main {
	overflow-y: hidden;
	&::after {
		content: "";
	    position: absolute;
	    right: 0;
	    left: 0;
	    bottom: -20%;
	    top: -20%;
	    width: 100%;
	    height: 140%;
	    background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),url(../images/bg-home-main.jpg) center center no-repeat;
		background-size: cover;
		z-index: -1;
	}
	.container {
		padding: 0;
		text-align: center;
		.row {
			align-items: flex-start;
		}
		.img-hdr-banner-icon {
			display: block;
			margin-right: auto;
			margin-left: auto;
			width: 100px;
		}
	}
}

.wrapper-guide-5 {
	.col-how-to-wear-item {padding-bottom: 2rem;}
}


.wrapper-science-main {
	/*background: url(../images/bg-banner-science.jpg) center center no-repeat;*/
	background: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)),url(../images/bg-banner-science.jpg) center center no-repeat;
	background-size: cover !important;
	text-align: center;
	.container {
		padding: 0;
		.row {
			align-items: flex-start;
		}
		h1, h2 {
			margin-bottom: 0.5rem;
		}
		.img-hdr-banner-icon {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 78px;
		}
	}
}

.wrapper-citations {
	padding-top: 4rem;
	padding-bottom: 0;
	.container {
		border-bottom: 1px solid #e1e1e1;
		padding-bottom: 2rem;
	}
}


@include media-breakpoint-up(sm) {

	.wrapper-home-3 {
		.col-clinical-results {
			margin-top: 0;
		}
	}

	.wrapper-harmful-sleep-3 {
		.row-risks {
			.col-risk-text {padding-left: 10px;}
		}
	}

	.wrapper-guide-5 {
		.col-how-to-wear-item {padding-bottom: 0;}
	}

	.img-main-stillbirth, .img-stillbirth-2 {width: 65%; margin: 1.5rem 0;}

	.wrapper-harmful-sleep-4 {
		.inline-image {max-width: 50%;}
	}
	.wrapper-pc-4 {
		.row-content {
			padding-bottom: 2rem;
		}
	}

	.wrapper-science-main {
		background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)),url(../images/bg-banner-science.jpg) center center no-repeat;
	}

	.wrapper-guide-main {
		text-align: left;
		z-index: 100;
		.container {
			text-align: left;
			.img-hdr-banner-icon {
				display: inline-block;
				margin-right: 0;
				margin-left: 40px;
				margin-top: 0.5rem;
				width: 100px;
			}
		}
	}
	.wrapper-guide-2 {z-index: 99;}

	.wrapper-science-main {
		text-align: left;
		.container {
			.img-hdr-banner-icon {
				display: inline-block;
				margin-left: 40px;
				margin-right: 0;
				margin-top: 0.5rem;
				width: 78px;
			}
		}
	}

}

@include media-breakpoint-up(md) {
	
	.wrapper-main {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: 9rem;
		min-height: 800px;
		section {
			width: 85%;
			margin: 0 auto;
		}
	}

	
	
	.wrapper-home-main {
		background: url(../images/bg-home-main.jpg) center center no-repeat;
		background-size: cover;
	}
	.wrapper-home-2  {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
	}
	.wrapper-home-3  {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		.row-order-now {
			padding-top: 4rem;
		}
	}


	.wrapper-harmful-sleep-3 {
		.row-risks {
			.col-risk-text {padding-left: 20px;}
		}
	}

	.logo-mayo-clinic {
		width: 100% !important;
		margin-top: 5rem;
	}


	.wrapper-pc-main {
		padding-bottom: 4rem;
    	min-height: auto;
	}

	// Sections - Stillbirth
	.wrapper-stillbirth-main {
	    background: url(../images/bg-stillbirth.jpg) center top no-repeat;
	    background-size: cover;
	    padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		text-align: left;
		.row-stillbirth-articles {
	    	.col-article {
	    		margin-top: 0;
	    	}
	    }
	}

	.wrapper-stillbirth-2 {
		background: url(../images/bg-stillbirth-2.jpg) center center no-repeat;
		background-size: cover;
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		section {
			width: 85%;
			margin: 0 auto;
		}
	}
	.wrapper-stillbirth-3 {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		background: none;
		&::after {

			content: "";
		    position: absolute;
		    right: 0;
		    left: 0;
		    top: 0;
		    width: 100%;
		    height: 50%;
		    /*background: url(../images/bg-stillbirth-3.jpg) center top no-repeat;*/
		    background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 55%, rgba(255,255,255,1) 100%),url(../images/bg-stillbirth-3.jpg) no-repeat center top;
			background-size: cover;
			z-index: -1;
		}
		.container {
			position: relative;
			z-index: 2;
		}
	}

	.row-stillbirth-articles {
		margin-top: 2.5rem;
		p {
			line-height: 1.4rem;
		}
		a {
			font-size: 1.1rem;
		}
	}

	.wrapper-harmful-sleep-main {
		padding-bottom: 2rem;
		min-height: auto;
	}

	.wrapper-harmful-sleep-2 {
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 45%, rgba(255,255,255,0.5) 100%),url(../images/bg-supine-sleep.jpg) no-repeat center bottom;
		background-size: contain;
		padding-bottom: $wrapper-padding-top-default;
		padding-bottom: 14rem;
	}
	
	.wrapper-harmful-sleep-3 {padding-top: 0;}

	.wrapper-harmful-sleep-4 {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		position: relative;
		/*background: linear-gradient(to bottom, rgba(21,42,119,0.9) 0%,rgba(21,42,119,0.4) 52%,rgba(21,42,119,0) 100%), url(../images/bg-harmful-sleep-bottom.jpg) center center no-repeat;
		background-size: cover;*/
		/*background-image: linear-gradient(to bottom, rgba(21,42,119,0.9) 0%,rgba(21,42,119,0) 100%), url(../images/bg-harmful-sleep-bottom.jpg);*/
		background-image: url(../images/bg-harmful-sleep-bottom.jpg);
		backround-position: center center;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		&::after {
			content: "";
		    position: absolute;
		    right: 0;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		    height: 100%;
		    background: linear-gradient(to bottom, rgba(21,42,119,0.9) 0%,rgba(21,42,119,0.4) 52%,rgba(21,42,119,0) 100%);
			z-index: -1;
		}
		.col-harmful-sleep-4-info {
			padding-top: 16rem !important;
		}
	}

	.wrapper-footer-callout {
		padding-top: 3rem;
		padding-bottom: 3rem;
		p {margin-bottom: 0;}
		input {margin-top: 0;}
		.btn {margin-top: 0;}
	}

	.hdr-track {
		height: 90px;
	}

	.wrapper-pc-2, .wrapper-pc-3, .wrapper-guarantee  {
		padding-bottom: 4rem;
	}

	.wrapper-pc-3 {
		.col-app-screens {
			margin-bottom: 0;
			text-align: center;
			.hdr-track {
				text-align: left;
				font-size: 80%;
			}
		}
	}

	.wrapper-pc-4 {
		padding-top: $wrapper-padding-top-default;
		padding-bottom: $wrapper-padding-bottom-default;
		.row-order-now {
			padding-top: 4rem;
		}
	}

	.wrapper-guide-main {
		section {
			padding: 5rem 0;
			text-align: left;
			.row {
				align-items: flex-start;
			}
			.img-hdr-banner-icon {
				float: left;
				margin-right: 1.5rem;
				margin-left: 20px;
				width: 100px;
			}
		}
	}

	.wrapper-science-main {
		section {
			padding: 7rem 0 9rem 0;
			text-align: left;
			.img-hdr-banner-icon {
				float: left;
				margin-right: 1.5rem;
				margin-left: 20px;
				width: 78px;
			}
		}
	}

}

.col-img-align {
		img {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}

@include media-breakpoint-up(lg) {
	.wrapper-footer-callout {
		h3 {font-size: 150%;}
		p {font-size: 80%;}
		text-align: left;
	}

	.wrapper-harmful-sleep-4 {
		.col-harmful-sleep-4-info {padding-top: 18rem !important;}
	}
	/*.col-img-align {
		img {
			width: 80%;
		}
	}*/
	.wrapper-stillbirth-3 {
		&::after {
			height: 55%;
			left: 50%;
		    transform: translateX(-50%);
		}
	}
	.wrapper-harmful-sleep-2 {
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0) 45%, rgba(255,255,255,1) 100%),url(../images/bg-supine-sleep.jpg) no-repeat center center;
		background-size: cover;
		padding-bottom: $wrapper-padding-top-default;
		padding-bottom: 8rem;
	}
	.wrapper-guide-main, .wrapper-science-main {
		padding-top: 6rem;
    	padding-bottom: 5rem;
	}
	.img-guarantee {
		margin-right: 2rem;
	}
	.wrapper-guarantee {
		padding-bottom: 6rem;
	}
	.wrapper-guarantee {
		padding-top: 0;
	}

}


@include media-breakpoint-up(xl) {
	.wrapper-default {
		padding-top: 4rem;
		padding-bottom: 4rem;;
	}
	.wrapper-footer-callout {
		h3 {font-size: 170%;}
	}
	.wrapper-harmful-sleep-2 {
		.img-supine-sleep {max-width: 320px;}
	}

	.wrapper-stillbirth-3 {
		&::after {
			height: 50%;
		}
		.row-extra-margin {padding-top: 6rem; padding-bottom: 2.5rem;}
	}
	.wrapper-pc-4 {
		.row-content {padding-bottom: 3rem;}
	}
	.img-guarantee {
		margin-right: 3rem;
	}
	.wrapper-guarantee {
		padding-bottom: 9rem;
	}
}

/*@include media-breakpoint-up(xxl) {
	.wrapper-stillbirth-3 {
		&::after {
			height: 50%;
		}
	}
}*/




// Rows and Columns
.row-harmful-sleep-graphs-1 {margin-top: 3rem;}
.row-harmful-sleep-graphs-1, .row-harmful-sleep-graphs-2 {
	.row-graph {margin-bottom: 2rem;}
	.graph-item {
		padding-bottom: 2.5rem;
		float: left;
		width: 100%;
	}
	.col-graph-hdr {
		text-align: left;
		.hdr-alt {white-space: nowrap;}
		.hdr-alt, .hdr-alt-sub {
			margin-bottom: 0;
		}
		.hdr-alt-sub {
			line-height: 1.3;
			margin-top: 0.3rem;
		}
	}
	.col-graph-image, .col-graph-hdr {
		padding-top: 3rem;
	}
}


.row-pc-main-2 {
	padding-top: 2rem;
	.img-pc-main-plus {
		max-width: 80px;
    	margin-bottom: 1rem;
	}
}

.row-supine-length {
	.supine-length-container {
		position: relative;
		margin-bottom: 1rem;	
	}
	.col-women-chart {
		margin-bottom: 1.5rem;
		.small {
			font-size: 100%;
		}
	}
}

.solution-comparison {
	padding-bottom: 2rem;

  i {
    color: red;
    font-style: normal;
  }

  .fa-check {
		color: $primary;
		display: block;
		font-size: xx-large;
		text-align: center;
    margin-bottom: 5px;
	}

	.logo-sensor {
		width: 75%;
	}

	table {
		border-collapse: separate;
		border-spacing: 0;
	}

	td, th {
		/* doesn't seem to have any effect - word-wrap: break-word; */
	}

	thead tr:nth-child(1) th {
		border-bottom: 1px solid #e6e6e6;
		background: white;
		position: sticky;
		top: 114px;
		z-index: 1;
	}
}
@include media-breakpoint-up(xl) {
	.solution-comparison {
		.u-table--v2 td, .u-table--v2 th {
			padding: 25px;
		}
	}
}
@include media-breakpoint-down(sm) {
	.solution-comparison {
		.solution-pc-logo {
			display: flex;
		}
		.logo-sensor {
			width: 100%;
		}

		tr {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;
			background-color: white!important;
		}

		td, th {
			display: block;
			width: 33.333333333333%;
			background: white;
		}

		th:first-child, td:first-child {
			background: #efefef;
			width: 100%;
			margin-top: 1em;
		}

		thead th:first-child {
			display: none;
		}

		thead tr:nth-child(1) {
			position: sticky;
			top: 114px;
		}

		thead tr:nth-child(2) {
			margin-top: 1em;

			td {
				align-self: center;
			}
		}
	}
}
@include media-breakpoint-down(xs)  {
	.solution-comparison {
		thead tr:nth-child(1), thead tr:nth-child(1) th {
			top: 64px;
		}
    .fa-check {
			font-size: x-large;
		}
	}
}

.row-mayo-trials {text-align: left;}

.reduction-container {margin-top: 2rem;}

.row-apps-platforms {
	text-align: center;
	font-size: 1.2rem;
	font-weight: 600;
	.img-iphone {
		display: block;
		width: 60%;
		margin: 0.4rem auto 1.4rem auto;
		@extend %phone-styles;
	}
	.img-android {
		display: block;
		width: 77%;
		margin: 0 auto;
	}
	.img-app-source {
		width: 40%;
		margin: 0 auto;
	}
}

.row-in-the-box {
	.col-box-item {
		text-align: center;
		margin-bottom: 2rem;
		.img-container {
			margin-bottom: 0.8rem;
			vertical-align: middle;
		}
		img {
			width: 50%;
			height: auto;
		}
	}
}

.row-how-to-wear {
	.col-how-to-wear-item {
		text-align: center;
		.img-container {
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		
	}
}

.row-tip {
	.icon {
		width: 40%;
		height: auto;
		display: block;
		margin: 1rem auto;
	}
}

.row-tech-specs {
	p {
		margin-bottom: 2rem;
	}
}

.row-citation {
	padding-top: 1rem;
	padding-bottom: 1rem;

	.small {
		font-size: 80%;
	}
}

.row-order-now .btn-row {
	margin-top: 1.5rem;
}



@include media-breakpoint-up(sm) {
	.row-pc-main-2 {
		padding-top: 2rem;
		.img-pc-main-plus {
			max-width: 100%;
			margin-bottom: 0;
		}
	}
	.row-harmful-sleep-graphs-1, .row-harmful-sleep-graphs-2 {
		.row-graph {margin-bottom: 0;}
	}
	.row-supine-length {
		.supine-length-container {
			margin-bottom: 1rem;	
		}
		.col-women-chart {
			margin-bottom: 0;
			.small {font-size: 80%}
		}
	}
	
	.row-in-the-box {
		.col-box-item {
			.img-container {
				margin-bottom: 0;
			}
			img {width: 68%;}
		}
	}
	.row-apps-platforms {
		.img-app-source {width: 70%;}
	}
	.row-citation {
		.col-source-text {
			padding-left: 1rem;
		}
	}
}

@include media-breakpoint-up(md) {
	.row-content {padding-top: 2rem;}
	.col-text-margin-top {
		padding-top: 2.5rem;
	}
	.row-harmful-sleep-graphs-1 {
		.col-graph-image, .col-graph-hdr {
			border-top: none;
		}
	}
	.row-mayo-trials {
		.logo-mayo-trial {
			padding: 0 1.5rem;
		}
	}
	.row-harmful-sleep-graphs-1, .row-harmful-sleep-graphs-2 {
		padding-bottom: 3rem;
		.graph-item {
			border-bottom: solid 3px #ceccdb;
			padding-bottom: 2.5rem;
			float: left;
			width: 100%;
		}
		.col-graph-hdr {
			text-align: left;
			.hdr-alt, .hdr-alt-sub {
				margin-bottom: 0;
			}
			.hdr-alt-sub {
				line-height: 1.3;
				margin-top: 0.3rem;
			}
		}
		.col-graph-image, .col-graph-hdr {
			padding-top: 3rem;
		}
	}

	.row-harmful-sleep-graphs-2 {
		.col-graph-image, .col-graph-hdr {
			border-top: 3px solid #ceccdb;
			padding-top: 3rem !important;
		}
	}

	.row-citation {
		.col-source-text {
			padding-left: 2rem;
			font-size: 1.1rem;
		}
	}

	.row-apps-platforms {
		.img-app-source {width: 50%;}
	}
}

@include media-breakpoint-up(lg) {
	.row-harmful-sleep-graphs-1 {margin-top: 3rem;}
	.row-mayo-trials {padding-top: 5rem;}
}

@include media-breakpoint-up(xl) {
	.row-mayo-trials {padding-top: 7rem;}
	.row-apps-platforms {
		.img-iphone {
			width: 48%;
			margin-top: 0.4rem;
			margin-bottom: 1.5rem;
		}
		.img-android {width: 62%;}
		.caption-phone-platform {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.row-pc-main-2 {
		.img-pc-main-plus {max-width: 120px;}
	}
	.row-apps-platforms {
		.img-app-source {
			width: 40%;
		}
	}
}





// Random

.chart-legend-item-1 {
	width: 49%;
	vertical-align: middle;
	display: inline;
	font-size: 1rem;
	font-weight: 400;
}
.chart-legend-item-2 {
	width: 49%;
	vertical-align: middle;
	display: inline;
	margin-left: 2%;
	font-size: 1rem;
}

.legend-item-1 {
	display: inline-block;
	height: 18px;
	width: 18px;
	background-color: $primary;
	margin-right: 0.5rem;
	vertical-align: middle;
	margin-left: 0.5rem;
}
.legend-item-2 {
	display: inline-block;
	height: 18px;
	width: 18px;
	background-color: #F73DAA;
	margin-right: 0.5rem;
	vertical-align: middle;
	margin-left: 0.5rem;
}


// Modal / Citations

.modal-backdrop.show{background-color: rgba(0,0,0,0.2);}
.risk-calculator-open .modal-backdrop.show {background-color: #000;}
.modal {padding-right: 0 !important;}
//.modal-open .navbar{
//  position:absolute;
//  width: 100%;
//}
body.modal-open {
	position: inherit;
	overflow-y: scroll;
	width: 100%;
	padding-right: 0!important;
}
// our default styling re-enables body scroll for citation modals, not sure why.
body.risk-calculator-open.modal-open {
	overflow-y: hidden;
}
.modal-dialog {
	max-width: 100%;
	width: 100%;
	margin: 0;
}
#riskCalculatorModal .modal-dialog {
	pointer-events: auto;
	min-height: 100vh;
	min-width: 360px;
	width: 90%;
	margin: 4em auto;
	background: #fff;

	.close-modal {
		position: absolute;
		right: 30px;
		top: 30px;
		z-index: 1;
	}
}
@media (max-width: 767px) {
  #riskCalculatorModal .modal-dialog .close-modal {
    right: 15px;
    top: 15px;
  }
}


.modal-content {
	/*background: rgba(71,57,126,0.9);*/
	background: linear-gradient(342deg, rgba(40,3,62,0.95), rgba(71,57,126,0.95));
	color: #fff;
	padding-top: 6rem;
	border: none;
	border-radius: 0;
	transition: all 0.5s;
}
.shrink-modal .modal-content {padding-top: 4rem; transition: all 0.5s;}
.modal-header {
	border-bottom: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.modal-body {
	font-size: 1.2rem;
	padding-top: 1rem;
    padding-bottom: 4rem;
}
.modal-title {
	font-size: 1.8rem;
	font-weight: 400;
}
.modal-hdr-type {
	color: #a18ca9;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 1.5rem;
	.icon-info {
		margin-left: 0;
		margin-right: 0.5rem;
		margin-top: -5px;
	}
}
.close-modal {
	width: 34px;
	height: 34px;
	cursor: pointer;
	opacity: 0.5;
}
.close-modal:hover, .close-modal:active, .close-modal:focus {opacity: 0.8}

@include media-breakpoint-up(lg) {
	.shrink-modal .modal-content {padding-top: 6rem;}
	.modal-content {
		padding-top: 10rem;
	}
}
@include media-breakpoint-up(xl) {
	.modal-content {
		padding-top: 10rem;
	}
	.shrink-modal .modal-content {padding-top: 8rem;}
	.close-modal {
		width: 45px;
		height: 45px;
	}
}

@include media-breakpoint-down(lg) {
	.footer-wrapper .row > div[class^="col"] {
		padding-top: 2rem;
	}
	small, .small {font-size: 100%;}
	.modal-body {padding-top: 0;}
	.modal-body div[class^="col"] {
		padding-top: 0;
	}
	.row-citation {
		padding-top: 0;
	}
}


@include media-breakpoint-down(xl) {
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@include media-breakpoint-down(md) {
	.nav-footer-primary, .nav-footer-secondary {
		margin-top: 2rem;
		.nav-link {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.navbar-secondary .nav-link {
		margin-bottom: 2.5px;
	}
	.menu-button .nav-link {
		margin-top: 0.4rem;
	}
	.col-footer-powered, .col-footer-contact {margin-top: 2rem;}
	
	.row > div[class^="col"] {
		padding-top: 1rem;
	}
	.modal-body .row .col-11 {
		padding-top: 0 !important;
	}
	.modal-header {
		padding-bottom: 0;
	}

	.btn-x-wide {
		font-size: 1rem;
	}
	.btn-x-wide {
		padding: .5rem 1.5rem;
	}

	.wrapper-home-2 {
		.row-content {padding-top: 1rem;}
	}
	.col-source-text {
		padding-top: 1rem !important;
	}
}


@include media-breakpoint-down(sm) {
	.img-align-small {
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		display: block;
	}
	.align-center-small {
		text-align: center;
	}
	.wrapper-science-main .container > .row, .wrapper-guide-main .container > .row {
		margin-left: 0;
		margin-right: 0;
	}
	.wrapper-harmful-sleep-4 {
	background: linear-gradient(to bottom, rgba(79,94,151,1) 0%, rgba(255,255,255,1) 100%);
	}
	.source-thumb {
		margin-right: auto;
		margin-left: auto;
	}
}
@include media-breakpoint-down(xs) {
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		padding-left: 5px;
		padding-right: 5px;
	}
	.nav-footer-primary, .nav-footer-secondary {
		ul {
			padding-left: 0;
		}
	}

	.btn-wide {
		font-size: 1rem;
	}
	.btn-wide {
		padding: .5rem 1.5rem;
	}
}
.source-thumb {
  max-width: 200px;
  position: relative;

  .download-source {
    height: 64px;
    width: 64px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.9;

    img {
      width: 100%;
    }
  }
  .download-source:hover {
    opacity: 1;
  }
  .download-source:active {
    height: 72px;
    width: 72px;
  }
}

#newsletterSignup {
  /* Hubspot ignores form fields it thinks are currently hidden */
  .newsletterSignupHidden {
    position: absolute;
    left: -9999px;
  }

  .newsletterSignupStep1, .newsletterSignupStep2, .newsletterSignupStep3 {
    display: none;
  }
  &.step1 .newsletterSignupStep1 {
    display: block;
  }
  &.step2 .newsletterSignupStep2 {
    display: block;
  }
  &.step3 .newsletterSignupStep3 {
    display: block;
  }
}

#shopify-section-general-footer #footerNavRiskCalculator, .shopify-order-status #footerNavRiskCalculator {
  display: none;
}

/* Put it UNDER the cookie notice, so it doesn't prevent the Accept button from being clickable there */
#hubspot-messages-iframe-container {
  z-index: 99999999 !important;
}

blockquote p {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid $primary;
  font-weight: 100;
  font-style: italic;
  &:before, &:after {
    content: '\201C';
    color: $primary;
    font-weight: 900;
  }
  &:after {
    content: '\201D';
  }
}

.blog {
	.blog-post {
		h2 {
			margin-top: 3rem;
			margin-bottom: 1rem;
		}
        h3 {
            margin-top: 2rem;
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
        }
	}

	/* social share icons */
	.u-icon-v1 {
		cursor: pointer;
	}
}

@include media-breakpoint-up(md) {
	.blog {
		.blog-post {
			h2 {
				margin-top: 4rem;
				margin-bottom: 1.5rem;
			}
            h3 {
                margin-top: 3rem;
                margin-bottom: 1.2rem;
                font-size: 1.95rem;
            }
		}
	}
}

/* For Shopify */
@media (max-width: 749px) {
	.page--banner .banner {
	    padding-left: 1.3rem !important;
	    padding-right: 1.3rem;
	}
	.main__content {
		padding-left: 1.3rem;
		padding-right: 1.3rem;
	}
	.order-summary-toggle {
		padding-left: 1.3rem;
		padding-right: 1.3rem;
	}
}
/* END For Shopify */

/* For HelpScout */

#mainNav.navbar {
	display: block;
	margin: 0;

  .navbar-inner {
    background: none;
    box-shadow: none;
  }

  .brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .collapse:not(.show) {
    display: block;
  }
  .nav {
  	position: relative;
	padding-top: 1rem;
  }
  .nav .active .caret {
    display: none;
  }
}

#docsSearch {
  margin: 0 0 3em 0 !important;
  padding: 3em 1.5em 1.5em 1.5em;
}
#searchBar {
  padding: 2rem 0 !important;

  button {
    top: 2rem !important;
  }
}
@media (min-width: 481px) {
  #searchBar {
    button {
      background: $primary;

      @include hover {
        background: #0e9de9;
      }
    }
  }
}
@media (max-width: 768px) {
  #searchBar {
    padding: 1.5rem 0 !important;

    button {
      top: 1.5rem !important;
    }
  }
}
#contentArea {
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
  h1, h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.4rem;
  }

  .twoCol p, .threeCol p, .twoCol ul, .threeCol ul {
    font-size: inherit;
  }

  #fullArticle {
    font-size: inherit;
    margin-bottom: 1rem;

    h1, h2, h3, h4, h5, h6, strong {
      color: inherit;
      line-height: 1.2;
    }
    strong {
      color: inherit;
    }

    /* Matches what's in the blog */
    h2 {
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 2rem;
    }
    h3 {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      font-size: 1.4rem;
    }
    /* TODO: h4, etc */

    p, ul, ol, li, div, blockquote, dd, table {
      font-size: inherit;
      margin-bottom: 1rem;
    }
  }

  .articleFoot {
    line-height: 2rem;

    p, time {
      font-family: inherit;
    }
    time {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  .collection-category h2 a, .twoCol h2 a, .threeCol h2 a {
    color: $link-color;
    text-decoration: $link-decoration;
    border-bottom: none;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      border-bottom: none;
    }
  }

  #sidebar .nav-list {
    display: block;

    a {
      font-size: 1rem;
      color: $link-color;
      text-decoration: $link-decoration;

      @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
      }

      &.disabled {
        color: #444 !important;
        text-decoration: none !important;
      }

      .icon-arrow {
        /* Instead of current category indication, show on all links to match Blog*/
        display: inline-block;
        float: left;
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 599px) {
  #fullArticle {
    img {
      float: none !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  time.lu {
    float: none !important;
    display: block;
  }
}

@include media-breakpoint-up(md) {
  #contentArea {
    h1, h2 {
      font-size: 2.5rem;
    }

    #fullArticle {
      /* Matches what's in the blog */
      h2 {
        margin-top: 4rem;
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
      }
      h3 {
        margin-top: 3rem;
        margin-bottom: 1.2rem;
        font-size: 1.95rem;
      }
      /* TODO: h4, etc. */
    }
  }
}

.contentWrapper {
  color: inherit !important;
}
#contactModal.modal {
  top: 8rem;
  bottom: auto;
  left: 50%;
  right: auto;
  z-index: 1150;

  h2 {
    line-height: 34px;
    color: inherit;
  }

  #closeBtn {
    line-height: 1;
  }
}
#contactModal.modal form, #contactForm {
  input[type=text], input[type=email], input[type=file], textarea, label {
    height: auto;
    font-size: inherit;
  }

  label.control-label {
    text-align: left;
  }
}
#contactForm {
  margin-top: 2rem;
}

#contactModal .btn, .form-actions .btn {color:#fff;background-color:#2cadf2;border-color:#2cadf2; background-image: none; text-shadow: none; }
#contactModal .btn:hover, .form-actions .btn:hover{color:#fff;background-color:#0e9de9;border-color:#0e9de9;}
#contactModal .btn:focus, #contactModal .btn.focus, .form-actions .btn:focus, .form-actions .btn.focus{box-shadow: 0 0 0 0.2rem rgba(44,173,242,0.5);}
#contactModal .btn:not(:disabled):not(.disabled):active, #contactModal .btn:not(:disabled):not(.disabled).active,.show> #contactModal .btn.dropdown-toggle,
.form-actions .btn:not(:disabled):not(.disabled):active, .form-actions .btn:not(:disabled):not(.disabled).active,.show> .form-actions .btn.dropdown-toggle
{color:#fff;background-color:#2cadf2;border-color:#2cadf2}
#contactForm .btn:not(:disabled):not(.disabled):active,#contactForm .btn:not(:disabled):not(.disabled).active,.show>#contactForm .btn.dropdown-toggle,
.form-actions .btn:not(:disabled):not(.disabled):active,.form-actions .btn:not(:disabled):not(.disabled).active,.show>.form-actions .btn.dropdown-toggle
{color:#fff;background-color:#2cadf2;border-color:#2cadf2}
#contactModal .btn:not(:disabled):not(.disabled):active:focus,#contactModal .btn:not(:disabled):not(.disabled).active:focus,.show> #contactModal .btn.dropdown-toggle:focus,
.form-actions .btn:not(:disabled):not(.disabled):active:focus,.form-actions .btn:not(:disabled):not(.disabled).active:focus,.show> .form-actions .btn.dropdown-toggle:focus
{box-shadow: 0 0 0 0.2rem rgba(44,173,242,0.5);}
#contactForm .control-group, #contactModal .control-group, .abuse .control-group {
    margin-bottom: 25px;
}
#contactModal .parsley-errors-list, #contactForm .parsley-errors-list, .abuse .parsley-errors-list {
	margin-top: 0.3rem;
}

@include media-breakpoint-up(xl) {
	#mainNav .container-fluid {
		max-width: 1260px;
		padding-left: 20px;
		padding-right: 20px;
	}
	#mainNav.navbar .nav {
		margin-left: 3rem;
	}
}

@media (max-width: 1300px) {
	#mainNav .navbar-inner {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;
	}
	#mainNav .brand {
	    width: 150px;
	    padding-left: 0 !important;
	    padding-right: 0 !important;
	}
	.navbar .btn-navbar {
	    display: block;
	}
	#mainNav .btn-navbar .icon-bar+.icon-bar {
	    margin-top: 3px;
	}
	#mainNav .btn-navbar {
	    padding: 0.75rem .8rem;
	    font-size: 1.25rem;
	    line-height: 1;
	    background-color: transparent;
	    border: 1px solid rgba(255,255,255,0.1);;
	    border-radius: .25rem;
	    right: -5px;
	    width: auto;
	    height: auto;
	    position: absolute;
		top: 2px;
		margin-top: 10px;
		text-shadow: none;
		background: none;
    	box-shadow: none;
	}
	#mainNav .btn-navbar .icon-bar {
	    width: 19px;
	    border-radius: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	    background: #fff;
	}
	#mainNav .nav li a {font-size: 1rem; display: inline-block; padding-top: 0.3rem;}
	#mainNav .btn-navbar .icon-bar+.icon-bar {
	    margin-top: 3px;
	}
	.nav-collapse, .nav-collapse.collapse {
	    overflow: hidden;
	    height: 0;
	}
	.nav-collapse.collapse {position: static;}
	.nav-collapse {clear: both;}
	.nav-collapse .nav {
	    float: none;
	    margin: 0 0 9px;
	    text-align: center;
	}
	#mainNav .nav li {display: block;}
}

@media (max-width: 576px) {
	#mainNav .navbar-inner {
		padding-left: 5px;
		padding-right: 5px;
	}
}

#wrapper-footer { display: block; }
footer.helpScout {
  display: block; /* we hide footer first, then add this class to it */

  p {
    color: inherit;
    text-align: inherit;
    margin-bottom: 1rem;
  }

  .nav > li > a:hover {
    background: none;
  }

	#footerNavRiskCalculator {
		display: none;
	}
}

/* Printing */
@media print {
  section.call-to-action, .articleFoot .help, .icon-contact {
    display: none !important;
  }

  .admin-edit {
    display: none !important;
  }

  .article-breadcrumb a:after {
    content: '' !important;
  }

  footer.helpScout, header#mainNav {
    display: none !important;
  }

  .green {
    color: green !important;
  }
  .blue {
    color: blue !important;
  }
  .red {
    color: red !important;
  }
  .orange {
    color: orange !important;
  }
}

 /* END HelpScout */

#disclaimer-content {
	display: none;
}

.specialOffer {
  /* display: none; */
}

.loadingIndicator {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 30px;
	width: 30px;
	margin-left: -15px;
	margin-top: -15px;
	border-left: 3px solid rgba(44,173,242,0.15);
	border-right: 3px solid rgba(44,173,242,0.15);
	border-bottom: 3px solid rgba(44,173,242,0.15);
	border-top: 3px solid rgba(44,173,242,0.8);
	border-radius: 100%;
	animation: rotation 0.6s infinite linear;
	transition: right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.testimonial-indicators {
	bottom: -3rem;
}

.slick-arrow.slick-disabled, .slick-arrow.slick-disabled:hover {
	cursor: default !important;
	background-color: #999999 !important;
	color: #fff !important;
	opacity: 0.1 !important;
}
